import React, { useState } from 'react';
import './Personalities.css'; // Import the CSS file
import { Animate, Card, Loader, useAPI } from 'components/lib';

export function Personalities({ onNewChat, initalChatLoading }) {
  const [showAll, setShowAll] = useState(false);
  const [selectedPersonality, setSelectedPersonality] = useState(null);

  const personalities = useAPI('/api/bot');

  if (personalities.loading || initalChatLoading) return <Loader />;

  const viewProfile = personality => setSelectedPersonality(personality);

  const closeProfile = () => setSelectedPersonality(null);

  return (
    <div className='container m-auto mt-8'>
      {selectedPersonality ? (
        <Animate type='pop-center'>
          <div className='container'>
            <button
              type='button'
              onClick={closeProfile}
              className='w-56 text-gray-900 hover:text-white border-2 border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-2.5 py-4 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
            >
              Back to Personalities
            </button>
          </div>
          <Card noPadding transparent>
            <Card
              noPadding
              className='flex flex-col-reverse justify-center lg:flex-row'
            >
              <Card noPadding className='text-left'>
                <h2>{selectedPersonality?.name}</h2>
                <p>
                  Focus Area:{' '}
                  {selectedPersonality?.traits?.map(t => t.name).join(', ')}
                </p>
                <p>AI Therapist @Ment.ai</p>
              </Card>

              <img
                className='rounded-full bg-center m-auto w-40'
                src={selectedPersonality?.image}
                alt='Personality?'
              />
            </Card>
            <hr class='border-t-2 border-gray-300 my-4' />
            <p>
              <strong>Personality:</strong> {selectedPersonality.personality}
            </p>
            <br />
            <p>
              <strong>Treatment Approach:</strong>{' '}
              {selectedPersonality.treatmentApproach}
            </p>
          </Card>
        </Animate>
      ) : (
        <>
          {personalities.data
            ?.slice(0, showAll ? personalities.data.length : 3)
            .map((personality, index) => (
              <div className='card' key={index}>
                <div>
                  <img
                    className='rounded-full bg-center m-auto w-80'
                    src={personality?.image}
                    alt='Personality?'
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '1rem',
                    textAlign: 'center',
                  }}
                >
                  <h3 className='mb-4 text-2xl text-gray-900 font-semibold'>
                    {personality?.name}
                  </h3>
                  <div className='mb-4 text-grey-darker text-sm flex-1 min-h-10 max-h-10 overflow-hidden break-words line-clamp-2'>
                    <p>
                      Focus: {personality?.traits.map(t => t.name).join(', ')}
                    </p>
                  </div>
                  <div>
                    <button
                      type='button'
                      className='w-32 text-gray-900 hover:text-white border-2 border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                      onClick={() => viewProfile(personality)}
                    >
                      Profile
                    </button>
                    <button
                      type='button'
                      className='w-32 text-gray-900 hover:text-white border-2 border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                      onClick={() => onNewChat(personality)}
                    >
                      Start Chating
                    </button>
                  </div>
                </div>
              </div>
            ))}
          <div className='container m-auto text-center'>
            <button
              type='button'
              onClick={() => setShowAll(!showAll)}
              className='w-64 text-gray-900 hover:text-white border-2 border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-4 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
            >
              {showAll ? 'Show Less' : 'Show all Personalities'}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
