/***
 *
 *   ONBOARDING
 *   Example of onboarding flow*
 *
 **********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, OnboardingV2, Form, Message } from 'components/lib';
import { Welcome } from './welcome';
import { Questionaire } from './questionaire';
import { Interest } from './interest';
import { Consent } from './consent';
import { BasicInformation } from './basicInfo';

export function OnboardingViewV2(props) {
  const context = useContext(AuthContext);
  const views = [
    {
      name: 'Getting Started',
      description: `Welcome, ${context.user.name}!`,
      component: <Welcome />,
    },
    {
      name: 'Basic Information',
      description: `Tell us about yourself.`,
      component: <BasicInformation />,
    },
    {
      name: 'Consent And Acknowledgement Form',
      description: `Please read carefully and agree to provided information to continue.`,
      component: <Consent />,
    },
    {
      name: 'Personality Questions',
      description: `Tell us more about yourself.`,
      component: <Questionaire />,
    },
    {
      name: 'Specific Area of Interest',
      description: `Please check all the items that describe your current situation. This will helps us to recommend you the best personality for you.`,
      component: <Interest />,
    },
  ];

  //   if (context.permission.admin) {
  //     views.push({
  //       name: 'Invite your team',
  //       description: 'Gravity is more fun when you invite your team.',
  //       component: <InviteUsers />,
  //     });
  //   }

  if (context.user.duplicate_user) {
    views.unshift({
      name: 'Important!',
      description: '',
      component: <DuplicateUser />,
    });
  }

  // return <OnboardingV2 save onFinish='/dashboard' views={views} />;
  return <OnboardingV2 save onFinish='/chat' views={views} />;
}

function DuplicateUser() {
  return (
    <Message
      type='warning'
      title={`You already have an account`}
      text='We noticed you have already registered an account, so we used your original password to save you any confusion in the future.'
    />
  );
}

function InviteUsers(props) {
  return (
    <Form
      inputs={{
        email: {
          label: 'Email',
          type: 'email',
          required: true,
        },
      }}
      buttonText='Send Invite'
      url='/api/invite'
      method='POST'
    />
  );
}
