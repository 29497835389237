import Logo from 'components/userLogo/logo';
import React, { useContext, useState } from 'react';
import ContentSection from 'components/pageTopSection';
import {
  Animate,
  Button,
  Card,
  Loader,
  ViewContext,
  useAPI,
} from 'components/lib';
import axios from 'axios';

export function Questionaire(props) {
  const context = useContext(ViewContext);
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState({});

  const handleAnswerChange = e => {
    const { name, value } = e.target;
    setAnswers(prevAnswers => ({ ...prevAnswers, [name]: value }));
  };

  const questions = useAPI('/api/questions');

  if (questions.loading || loading) return <Loader />;

  const onSubmitAnswers = async () => {
    // if (Object.keys(answers).length < questions.data.length) {
    //   return context.notification.show(
    //     'Please answer all questions',
    //     'error',
    //     true
    //   );
    // }

    try {
      if (Object.keys(answers).length > 0) {
        await axios.post('/api/answers', answers);
        context.notification.show('Answers saved', 'success', true);
      }
      props.next();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className='container m-auto text-center'>
        <div className='lg:relative'>
          {/* <Logo /> */}
          {/* <ContentSection
            title='Intake Questionsaire'
            subtitle='We are happy to have you here username'
            description='Before we can get started, we need you to answer a few questions so we can best serve you. Please complete the following sections and then we can connect you with an Al to assist you.'
          /> */}
          <p className=' w-full m-auto my-3'>
            HealthM and your therapist ask that you complete this form to the
            best of your ability. While you are not required to supply the
            information requested, know that the more information you provide,
            the better HealthM is able to meet your specific needs. This
            information may be considered confidential; however, certain
            otherwise confidential information may be shared as required by law.
            The completed intake form will be kept in the client file and
            maintained under the same confidentiality protections as the
            therapeutic record, as detailed in the HealthM Disclosure Statement
            and HIPAA Form.
          </p>
        </div>
      </div>
      <div className='container m-auto mt-8 text-center'>
        <h2 className='text-2xl font-normal my-3 text-center font-semibold leading-normal text-gray-900 dark:text-white'>
          Current Concerns
        </h2>
        <Animate type='pop'>
          <Card className='m-auto' noPadding>
            <form>
              <div className='space-y-12 w-full m-auto my-3 mt-10 text-left'>
                {questions.data?.map((q, index) => (
                  <div key={q.id} className='mt-5'>
                    <label className='block text-gray-700'>{q.question}</label>
                    <textarea
                      id={`${q.id}`}
                      name={`${q.id}`}
                      value={answers[`${q.id}`] || ''}
                      onChange={handleAnswerChange}
                      className='w-full px-3 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-2 h-40'
                    />
                  </div>
                ))}
              </div>
              <Button
                // fullWidth
                className='m-auto w-1/2'
                text='Submit Answers'
                color='green'
                iconButton
                small
                action={onSubmitAnswers}
              />
            </form>
          </Card>
        </Animate>
      </div>
    </>
  );
}
