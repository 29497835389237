import { useEffect } from 'react';

export function ModalV2({ isOpen, onClose, children, small }) {
  const modalStyles = {
    modal: isOpen
      ? 'fixed inset-0 flex items-center justify-center z-50 overflow-auto'
      : 'hidden',
    overlay: isOpen ? 'fixed inset-0 bg-black opacity-50' : 'hidden',
    content: isOpen
      ? small
        ? 'relative bg-white w-3/5  max-w-none p-4 rounded shadow-lg overflow-auto'
        : 'relative bg-white w-4/5 h-4/5 md:w-4/5 md:h-4/5 max-w-none p-4 rounded shadow-lg overflow-auto'
      : 'hidden',
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    // Clean up function to unset overflow when the component is unmounted or modal is closed
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.overlay}></div>
      <div className={modalStyles.content}>
        {children}
        <button
          onClick={onClose}
          className='text-xl absolute top-0 right-2 p-3 hover:text-red-500 focus:outline-none '
        >
          X
        </button>
      </div>
    </div>
  );
}
