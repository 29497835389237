import React, { useState } from 'react';
import { Profile } from './profile';
import { Password } from './password';
import { TwoFA } from './2fa';

export function ProfileModalBase() {
  const ProfileNavList = ['Profile', 'Password', '2FA'];
  const [view, setView] = useState(ProfileNavList[0]);

  const renderComponent = () => {
    switch (view) {
      case 'Profile':
        return <Profile modal />;
      case 'Password':
        return <Password modal />;
      default:
        return <TwoFA modal />;
    }
  };

  return (
    <>
      <nav
        className='relative pt-5 px-5 bg-white -ml-5 -mr-6 -mt-5 mb-5 border-solid border-slate-100 border-t 
        dark:bg-slate-800 dark:border-0'
      >
        {ProfileNavList.map(item => (
          <button
            onClick={() => setView(item)}
            key={item}
            className={`inline-block text-slate-600 mr-5 pb-5 ${
              view === item ? 'underline' : ''
            } dark:text-slate-200`}
          >
            {item}
          </button>
        ))}
      </nav>
      {renderComponent()}
    </>
  );
}
