import { Acknowledgement } from "views/acknowledgement/acknowledgement";

const Routes = [
  {
    path: '/acknowledgement',
    view: Acknowledgement,
    layout: 'app',
    permission: 'user',
    title: 'Acknowledgement',
  },
];

export default Routes;