import PropTypes from 'prop-types';
import { MdPerson } from 'react-icons/md';
import moment from 'moment';
import Markdown from 'components/markdown/markdown';

const Message = props => {
  const { id, createdAt, text, ai = false } = props.message;

  if (text === 'Xq7#zG!pL3%vMj1^&W8kY') return null;

  return (
    <div
      key={id}
      className={`flex items-start mb-2 lg:px-20 px-5 py-3 gap-2 flex-row-reverse  ${
        ai ? 'flex-row-reverse justify-end bg-[#f7f7f7] border-y' : 'flex-row justify-end'
      }`}
    >
      <div
        className={` w-screen overflow-hidden chat ${
          ai ? 'chat-start' : ''
        }`}
      >
        <div className='chat-bubble text-neutral-content bg-transparent p-0 '>
          <Markdown markdownText={text} />
          <div className={`${ai ? 'text-left' : ''} text-xs`}>
            {moment(createdAt).calendar()}
          </div>
        </div>
      </div>
      {/* )} */}

      <div className='avatar'>
        <div className='w-8 h-8 border rounded-full border-slate-400'>
          {ai ? (
            <img
              className='w-8 h-8 m-auto'
              src={props?.selectedPersonality?.image}
              alt='AI bot'
            />
          ) : (
            <MdPerson className='w-6 h-full m-auto' />
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;

Message.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    createdAt: PropTypes.number.isRequired,
    text: PropTypes.string,
    ai: PropTypes.bool,
    selected: PropTypes.string,
  }).isRequired,
};
