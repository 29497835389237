import { Personalities } from 'views/personalities/personalities';

const Routes = [
  {
    path: '/personalities',
    view: Personalities,
    layout: 'app',
    permission: 'user',
    title: 'Personalities',
  },
];

export default Routes;