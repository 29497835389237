import { infoForm } from "views/infoform/infoform";

const Routes = [
  {
    path: '/infoForm',
    view: infoForm,
    layout: 'app',
    permission: 'user',
    title: 'Basic Information',
  },
];

export default Routes;