import { Therapist } from 'views/therapist/therapist';

const Routes = [
  {
    path: '/therapist',
    view: Therapist,
    layout: 'app',
    permission: 'user',
    title: 'Therapist',
  },
];

export default Routes;