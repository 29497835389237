import axios from 'axios';
import { useState } from 'react';
const Settings = require('../../settings.json');

/**
 * A custom hook for managing the conversation between the user and the AI.
 *
 * @returns {Object} An object containing the `messages` array, the `addMessage` function, the `clearMessages` function, and the `loadMessage` function.
 */
const useMessageCollection = () => {
  const [messages, setMessages] = useState([]);
  const [sessionId, setSessionId] = useState();
  const [messageLoading, setMessageLoading] = useState(false);
  const [selectedPersonality, setSelectedPersonality] = useState(null);
  const [initalChatLoading, setInitalChatLoading] = useState(false);

  async function ask(prompt, botId, newChat = false) {
    if (newChat) setInitalChatLoading(true);

    try {
      const res = await axios.post(
        `${Settings[process.env.NODE_ENV].server_url}/api/ask`,
        {
          prompt: prompt,
          sessionId: newChat ? 'new' : sessionId,
          botId: botId || selectedPersonality?._id,
        }
      );

      if (newChat) {
        setSessionId(res.data.sessionId);
      }

      const id = Date.now() + Math.floor(Math.random() * 1000000);
      addMessage({
        id: id,
        createdAt: Date.now(),
        text: res.data.content,
        ai: res.data.role === 'assistant',
        role: res.data.role,
        selected: 'chatgpt-3.5-turbo',
      });
      return res.data.sessionId;
    } catch (err) {
      console.log(err);
    } finally {
      setInitalChatLoading(false);
    }
  }

  const getMessagesBySessionId = async session_id => {
    setSessionId(session_id);
    try {
      setMessageLoading(true);
      const res = await axios.get(
        `${Settings[process.env.NODE_ENV].server_url}/api/message/${session_id}`
      );
      setMessages(
        res.data?.map((m, i) => ({
          id: i,
          createdAt: Date.now(),
          ai: m.role === 'assistant',
          text: m.content,
          role: m.role,
          selected: 'chatgpt-3.5-turbo',
        }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setMessageLoading(false);
    }
  };

  /**
   * A function for adding a new message to the collection.
   *
   * @param {Object} message - The message to add to the collection.
   */
  const addMessage = message => {
    setMessages(prev => [...prev, message]);
  };

  /**
   * A function for clearing all messages in the collection and resetting to the initial message.
   */
  const clearChat = () => {
    setMessages([]);
  };

  return {
    setSessionId,
    sessionId,
    ask,
    getMessagesBySessionId,
    messages,
    addMessage,
    setMessages,
    clearChat,
    selectedPersonality,
    setSelectedPersonality,
    initalChatLoading,
    messageLoading,
  };
};

export default useMessageCollection;
