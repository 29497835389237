import Logo from "components/userLogo/logo";
import { useState } from "react";
import FormBaseInput from '../formBaseInput/baseInput';
import BaseSelect from '../formBaseInput/baseSelect'


export function MyForm(sprops) {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
      });

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };


      const [gender, setGender] = useState('');
      const handleGenderChange = (e) => {
       setGender(e.target.value);
      };

  const genderOptions = ['Select Your Gender', 'Male', 'Female', 'Other'];

  return (
    <>
      <div className="container m-auto text-center">

        <div className="relative">
         < Logo />
          <h2 className="w-full text-3xl font-normal leading-normal text-gray-900 dark:text-white mt-3 absolute top-0">Basic Information</h2>
        </div>
        <p className="text-2xl font-normal my-3 leading-normal text-gray-900 dark:text-white my-3">We are happy to have you here username</p>
        <p className="text-2xl font-normal my-3 leading-normal text-gray-900 dark:text-white">Please provide the below Information as accurately as you can.</p>
      </div>
      <div className="container m-auto mt-8">
      <h2 className="text-3xl font-normal my-3 text-center font-semibold leading-normal text-gray-900 dark:text-white">Personal Information</h2>
      <form>
      <div className="space-y-12">
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <FormBaseInput
              label="First Name"
              name="firstName"
              type="text"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
            />

            <FormBaseInput
            label="Last Name"
              name="lastName"
              type="text"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
            />


            <FormBaseInput
              label="Date Of Birth"
              name="dob"
              type="date"
              placeholder="Date Of Birth"
              value={formData.dob}
              onChange={handleChange}
            />

            <FormBaseInput
              label="Time Zone"
              name="timeZone"
              type="text"
              placeholder="Time Zone"
              value={formData.timeZone}
              onChange={handleChange}
            />

            <BaseSelect
              label="Gender"
              id="gender"
              name="gender"
              placeholder="Select Your Gender"
              options={genderOptions}
              value={gender}
              onChange={handleGenderChange}
            />
            <FormBaseInput
              label="Phone Number"
              name="phoneNumber"
              type="number"
              placeholder="202-555-1212"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
       </div>

          <h2 className="text-3xl font-normal my-3 text-center font-semibold leading-normal text-gray-900 dark:text-white">Emergency Point Of Contact</h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

          <FormBaseInput
              label="POC First Name"
              name="PocFirstName"
              type="text"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
            />

            <FormBaseInput
            label="POC Last Name"
              name="PocLastName"
              type="text"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
            />


              <FormBaseInput
              label="Phone Number"
              name="PocPhoneNumber"
              type="number"
              placeholder="202-555-1212"
              value={formData.PocPhoneNumber}
              onChange={handleChange}
            />

            <FormBaseInput
              label="Relationship to Client"
              name="relationshipClient"
              type="text"
              placeholder="Relationship to Client"
              value={formData.relationshipClient}
              onChange={handleChange}
            />  

          </div>
      </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
          <button type="submit" className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
        </div>
      </form>
      </div>
    </>
  );
}

