import React, { useState } from 'react';
import Logo from "components/userLogo/logo";
import ContentSection from 'components/pageTopSection';


export function Therapist(props) {
  const [therapist, setTherapist] = useState([
    { id: 1, label: 'Abuse/trauma - physical, sexual, emotional, neglect', isChecked: false },
    { id: 2, label: 'Aggression, violence', isChecked: false },
    { id: 3, label: 'Alcohol use', isChecked: false },
    { id: 4, label: 'Anger, hostility, arguing, irritability', isChecked: false },
    { id: 5, label: 'Anxiety, nervousness', isChecked: false },
    { id: 6, label: 'Attention, concentration, distractibility', isChecked: false },
    { id: 7, label: 'Career concerns, goals, and choices', isChecked: false },
    { id: 8, label: 'Childhood issues', isChecked: false },
    { id: 9, label: 'Codependence', isChecked: false },
    { id: 10, label: 'Confusion', isChecked: false },
    { id: 11, label: 'Compulsions and/or obsessions (thoughts or actions that repeat themselves)', isChecked: false },
    { id: 12, label: 'Decision-making, indecision, mixed feelings, putting off decisions', isChecked: false },
    { id: 13, label: 'Delusions (false ideas)', isChecked: false },
    { id: 14, label: 'Dependence', isChecked: false },
    { id: 15, label: 'Depression, low mood, sadness, crying', isChecked: false },
    { id: 16, label: 'Divorce, separation, marital conflict, infidelity/affairs', isChecked: false },
    { id: 17, label: 'Drug use - prescription medications, over-the-counter medications, street drugs', isChecked: false },
    { id: 18, label: 'Eating problems - overeating, undereating, appetite, vomiting', isChecked: false },
    { id: 19, label: 'Emptiness', isChecked: false },
    { id: 20, label: 'Failure', isChecked: false },
    { id: 21, label: 'Fatigue, tiredness, low energy', isChecked: false },
    { id: 22, label: 'Fears, phobias', isChecked: false },
    { id: 23, label: 'Financial or money troubles, debt, impulsive spending, low income', isChecked: false },
    { id: 24, label: 'Gambling', isChecked: false },
    { id: 25, label: 'Grieving, mourning, deaths, losses, divorce', isChecked: false },
    { id: 26, label: 'Guilt', isChecked: false },
    { id: 27, label: 'Headaches, other kinds of pains', isChecked: false },
    { id: 28, label: 'Health, illness, medical concerns, physical problems', isChecked: false },
    { id: 29, label: 'Inferiority feelings', isChecked: false },
    { id: 30, label: 'Impulsiveness, loss of control, outbursts', isChecked: false },
    { id: 31, label: 'Irresponsibility', isChecked: false },
    { id: 32, label: 'Judgment problems, risk taking', isChecked: false },
    { id: 33, label: 'Legal matters, charges, suits', isChecked: false },
    { id: 34, label: 'Loneliness', isChecked: false },
    { id: 35, label: 'Memory problems', isChecked: false },
    { id: 36, label: 'Mood swings', isChecked: false },
    { id: 37, label: 'Oversensitivity to rejection', isChecked: false },
    { id: 38, label: 'Panic or anxiety attacks', isChecked: false },
    { id: 39, label: 'Perfectionism', isChecked: false },
    { id: 40, label: 'Pessimism', isChecked: false },
    { id: 41, label: 'Procrastination, lack of motivation', isChecked: false },
    { id: 42, label: 'Relationships problems (with friends, with relatives, or at work)', isChecked: false },
    { id: 43, label: 'School problems', isChecked: false },
    { id: 44, label: 'Self-centeredness', isChecked: false },
    { id: 45, label: 'Self-esteem', isChecked: false },
    { id: 46, label: 'Self-neglect, poor self-care', isChecked: false },
    { id: 47, label: 'Sexual issues, dysfunctions, conflicts, identity issues', isChecked: false },
    { id: 48, label: 'Sleep problems (too much, too little, insomnia, nightmares)', isChecked: false },
    { id: 49, label: 'Spiritual, religious, moral, ethical issues', isChecked: false },
    { id: 50, label: 'Stress and tension', isChecked: false },
    { id: 51, label: 'Suspiciousness', isChecked: false },
    { id: 52, label: 'Suicidal thoughts', isChecked: false },
    { id: 53, label: 'Temper problems, self-control, low frustration tolerance', isChecked: false },
    { id: 54, label: 'Thought disorganization and confusion', isChecked: false },
    { id: 55, label: 'Threats, violence', isChecked: false },
    { id: 56, label: 'Weight and diet issues', isChecked: false },
    { id: 57, label: 'Withdrawal, isolation', isChecked: false },
    { id: 58, label: 'Work problems, employment issues', isChecked: false },
  ]);

  const handleTherapistChange = (id) => {
    // Update the state when an Therapistis toggled
    setTherapist((prevTherapist) =>
      prevTherapist.map((therapist) =>
        therapist.id === id ? { ...therapist, isChecked: !therapist.isChecked } : therapist
      )
    );
  };

  return (
    <>
      <div className="container m-auto text-center">
      <div className="lg:relative">
         < Logo />
         < ContentSection
              title='Therapist'
              subtitle='Welcome back username'
              description='Please check all of the items below that describe your situation. These will help us paid you with the right personalities to talk to about these things.'
           />
        </div>
      </div>

      <div className="container m-auto mt-8 lg:w-1/2 w-full">
        <ul>
          {therapist.map((therapist) => (
            <li key={therapist.id}>
              <label>
                <input
                  type="checkbox"
                  className='mr-2'
                  checked={therapist.isChecked}
                  onChange={() => handleTherapistChange(therapist.id)}
                />
                {therapist.label}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}