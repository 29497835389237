import { useState, useEffect, useContext } from 'react';
import { MdClose, MdMenu } from 'react-icons/md';
import { AiFillRobot,AiFillDelete, AiOutlinePlus } from 'react-icons/ai';

import {
  AuthContext,
  Button,
  Card,
  Modal,
  ModalV2,
  ViewContext,
} from 'components/lib';
import axios from 'axios';

const SideBar = ({
  chatHistory,
  onSelectChat,
  onCreateNewChat,
  resetChat,
  openProfileModal,
  setIsSidebarOpen
}) => {
  const [open, setOpen] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeChat, setActiveChat] = useState(null);
  const authContext = useContext(AuthContext);
  const [deleteChats,setDeleteChat]=useState();
  const [accept,setAccept]=useState();

  const toggleDelete = () => {setOpenDelete(!openDelete)
    setAccept("")
  };

  function handleResize() {
    window.innerWidth <= 720 ? setOpen(false) : setOpen(true);
  }

  async function deleteChat(chat) {
    try {
      setLoading(true);
      await axios.delete(`/api/conversation/${chat?.session_id}`);
      await resetChat();
      setAccept("")
      toggleDelete();
    } catch (error) {
      console.log('Error while deleting chat', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleResize();
  }, []);
useEffect(()=>{
  setIsSidebarOpen(open)
},[open])
  return (
    <section
      className={`${
        open ? 'w-48' : 'w-16'
      } bg-slate-800 flex flex-col items-center h-screen fixed z-50 duration-100 shadow-md   justify-between`}
    >
      <div className='w-full p-3 '>
      <div className='flex items-center justify-between w-full px-2 mx-auto' >
        <div
          className={` ${
            !open && 'scale-0 hidden'
          } flex flex-row items-center gap-2 mx-auto w-full`}
        ></div>
        <div
          className='mx-auto btn btn-square btn-ghost'
          onClick={() => setOpen(!open)}
        >
          {open ? <MdClose size={15} /> : <MdMenu size={15} />}
        </div>
      </div>

      <ul className='w-full menu rounded-box'>
        <li>
          <button
            className='border border-slate-500 w-full flex items-center mt-4 p-1 rounded text-white text-sm'
            onClick={onCreateNewChat}
          >
            <AiOutlinePlus size={15} />
            <p className={`${!open && 'hidden'} text-sm ml-1`}>New Chat</p>
          </button>
        </li>
      </ul>

      <ul className='w-full menu rounded-box'>
        {chatHistory.map((chat, i) => (
          <li className='flex justify-center items-center' key={i}>
     
            <button
              onDoubleClick={() =>{
                
                setDeleteChat(chat)
                toggleDelete()}}
              href='#'
              className={`border border-slate-500 w-full flex items-center mt-3 p-1 rounded text-white text-sm ${
                activeChat === chat ? 'bg-[#353540] border-[]' : ''
              }`}
              onClick={() => {
                onSelectChat(chat);
                setActiveChat(chat);
              }}
            >
              <div className='flex items-center'>
                <img src={chat?.bot_id?.image} className='w-6' alt='Bot' />
                <p
                  className={`truncate whitespace-nowrap ${!open && 'hidden'}`}
                  style={{ maxWidth: '150px' }}
                >
                  {chat?.bot_id?.name}
                </p>
              </div>
            </button>
         {open&&   <div className=' w-12 h-10 cursor-pointer flex justify-center items-center pt-2 ml-2' onClick={() => {
                  //  onSelectChat(chat);
                  //  setActiveChat(chat);
                  setDeleteChat(chat)
              toggleDelete()
              }}>
  
  <AiFillDelete
    style={{
      color: "#ff0a0a",
      height:25,
      width:30,
     
    }}
  />
            </div>}

          </li>
        ))}
      </ul>
      </div>
<div className='w-full p-3'>
<Button
                textOnly
                action={authContext?.signout}
                text='Logout'
                className="bg-[#10B981] h-10 w-24 text-white  flex float-right my-2  justify-center items-center rounded"

              />
      <div className='w-full items-center justify-between flex space-beye border-t border-slate-500 pt-5 px-4'>
        <div className='w-10'>
          {authContext.user?.avatar ? (
            <img src={authContext.user?.avatar} alt='Profile' />
          ) : null}
        </div>

        <button
          onClick={openProfileModal}
          className='justify-end flex cursor-pointer'
        >
          <span className='bg-white w-1	h-1 block ml-1 border rounded'></span>
          <span className='bg-white w-1	h-1 block ml-1 border rounded'></span>
          <span className='bg-white w-1	h-1 block ml-1 border rounded'></span>
        </button>

      </div>
</div>

      <ModalV2 isOpen={openDelete} onClose={toggleDelete} small={true}>
              <Card
                noPadding
                className='flex flex-col justify-center items-center'
              >
                <p className='py-5 text-center'>
                  Are you sure you want to delete therapy session with Dr.
                  {deleteChats?.bot_id?.name}
                </p>
                <p className='py-5 text-center'>
                 Please Enter "Accept" in the below field
                  {/* {deleteChats?.bot_id?.name} */}
                </p>
                <input className='border-2 border-gray-400 rounded mb-10  p-2' placeholder='Type Accept' value={accept} onChange={(e)=>{setAccept(e.target.value)}}></input>
                <Button
                  color='red'
                  loading={loading}
                  opacity={accept=='Accept'?"opacity-100":"opacity-60"}
                  text='Confirm'
                  action={() => deleteChat(deleteChats)}
                  disabled={accept=='Accept'?false:true}
                  small
                />
              </Card>
            </ModalV2>
    </section>
  );
};

export default SideBar;
