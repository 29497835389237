/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and user)
 *
 *   PROPS
 *   children: will be passed from router > view > here (component(s), required)
 *   title: title of the view for the header (string, required)
 *
 **********/

import { Fragment, useContext } from 'react';
import { AuthContext, AppNav, Header, User } from 'components/lib';
import Style from './app.tailwind.js';

export function AppLayout(props) {
  // context & style
  const context = useContext(AuthContext);

  return (
    <Fragment>
      <AppNav
        items={[
          { label: 'Chat', icon: 'message-square', link: '/chat' },
          // { label: 'Dashboard', icon: 'activity', link: '/dashboard' },
          { label: 'Account', icon: 'user', link: '/account' },
          { label: 'Help', icon: 'help-circle', link: '/help' },
          { label: 'Sign Out', icon: 'log-out', action: context.signout },
        ]}
      />

      <main className={Style.app}>
        <Header title={props.title}>
          <User />
        </Header>

        {<props.children {...props.data} />}
      </main>
    </Fragment>
  );
}
