/***
*
*   AUTH NAV
*   Navigation used on the auth pages. Renders a dashboard link
*   if the user is signed in or a sign-up link if they are not
*
*   PROPS
*   transparent: apply a transparent background color (boolean, optional)
*
**********/

import { Fragment, useContext } from 'react';
import { AuthContext, Logo, Link, Button, Content, ClassHelper } from 'components/lib';
import Style from './auth.tailwind.js';

export function AuthNav(props){

  // context
  const context = useContext(AuthContext);

  const css = ClassHelper(Style, {

    wrapper: true,
    color: !props.transparent,
    transparent: props.transparent

  });

  return(
    <section className={ css }>
      <Content>

        <Logo className={ Style.logo }/>
        <nav className={ Style.nav }>

          { context.user?.token ?
            // <Button small goto='/dashboard' text='Dashboard' className={ Style.button } /> :
            <Button small goto='/chat' text='App' className={ Style.button } /> :
            <Fragment>
              <Link url='/' text='Sign In' className={ Style.link } color='white'/>
              <Button small goto='/signup' text='Sign Up' className='inline-block' />
            </Fragment>
          }
        </nav>
      </Content>
    </section>
  );
}