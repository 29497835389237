import React, { useState } from 'react';
import logo from '../../images/per-1.png';
import FormBaseInput from 'components/formBaseInput/baseInput';

export function Acknowledge(props) {


  const [acknowledgement, setAcknowledgement] = useState(
    {
      signature: '',
      dateSigned: '',
      signature1: '',
      dateSigned1: '',
    }
  );

  const handleAckChange = (e) => {
    const { name, value } = e.target;
    setAcknowledgement({ ...acknowledgement, [name]: value });
  };

  return (
    <>
      <div className="container m-auto text-center">
      <div className="relative">
          <div className='img'>
          <img className="w-16 h-16 border-2 rounded-full border-black" src={logo} alt="User Logo" />
          </div>
          <h2 className="w-full text-3xl font-normal leading-normal text-gray-900 dark:text-white mt-3 absolute top-0">Consent and Acknowledgement Forms</h2>
        </div>
        <p className="text-2xl font-normal leading-normal text-gray-900 dark:text-white">Welcome back username</p>
        <p className="text-3xl font-normal leading-normal text-gray-900 dark:text-white my-10">Informed Consent Agreement</p>
        <div className='p-5 lg:mx-32 m-auto border-2 border-neutral-900	 rounded-2xl'>
          <p className='mb-5'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 lg:mx-32 m-auto text-left">
          <FormBaseInput
              name="signature"
              type="text"
              placeholder="Signature"
              value={acknowledgement.signature}
              onChange={handleAckChange}
            />

            <FormBaseInput
              name="dateSigned"
              type="text"
              placeholder="Date Signed"
              value={acknowledgement.dateSigned}
              onChange={handleAckChange}
            />
          </div>

         <p className="text-3xl font-normal leading-normal text-gray-900 dark:text-white my-10">Acknowledgement Form</p>
        <div className='p-5 lg:mx-32 m-auto border-2 border-neutral-900	 rounded-2xl'>
          <p className='mb-5'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 lg:mx-32 m-auto text-left">

          <FormBaseInput
              name="signature1"
              type="text"
              placeholder="Signature"
              value={acknowledgement.signature1}
              onChange={handleAckChange}
            />

            <FormBaseInput
              name="dateSigned1"
              type="text"
              placeholder="Date Signed"
              value={acknowledgement.dateSigned1}
              onChange={handleAckChange}
            />

        </div> 


      </div>
    </>
  );
}