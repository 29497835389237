import React from 'react';

function BaseSelect({ id, name, label, placeholder, options, value, onChange }) {
  return (
    <div className="sm:col-span-3">
    <div className="mt-2">
      <label htmlFor={id} className="block text-gray-700">
        {label}
      </label>
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className="w-full px-3 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1"
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
    </div>
  );
}

export default BaseSelect;