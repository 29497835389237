import Logo from 'components/userLogo/logo';
import React, { useContext, useState } from 'react';
import ContentSection from 'components/pageTopSection';
import {
  Animate,
  AuthContext,
  Button,
  Card,
  Form,
  Loader,
  Row,
  ViewContext,
  useAPI,
} from 'components/lib';
import axios from 'axios';

const COMMON_TZ = [
  {
    value: 'UTC+0',
    label: 'UTC (Coordinated Universal Time): UTC+0',
  },
  {
    value: 'UTC+0',
    label: 'GMT (Greenwich Mean Time): UTC+0',
  },
  {
    value: 'UTC+1',
    label: 'BST (British Summer Time): UTC+1 (During daylight saving time)',
  },
  {
    value: 'UTC+1',
    label: 'CET (Central European Time): UTC+1',
  },
  {
    value: 'UTC+2',
    label:
      'CEST (Central European Summer Time): UTC+2 (During daylight saving time)',
  },
  {
    value: 'UTC-5',
    label: 'EST (Eastern Standard Time): UTC-5',
  },
  {
    value: 'UTC-4',
    label: 'EDT (Eastern Daylight Time): UTC-4 (During daylight saving time)',
  },
  {
    value: 'UTC-8',
    label: 'PST (Pacific Standard Time): UTC-8',
  },
  {
    value: 'UTC-7',
    label: 'PDT (Pacific Daylight Time): UTC-7 (During daylight saving time)',
  },
  {
    value: 'UTC+9',
    label: 'JST (Japan Standard Time): UTC+9',
  },
  {
    value: 'UTC+10',
    label: 'AEST (Australian Eastern Standard Time): UTC+10',
  },
  {
    value: 'UTC+9:30',
    label: 'ACST (Australian Central Standard Time): UTC+9:30',
  },
  {
    value: 'UTC+8',
    label: 'AWST (Australian Western Standard Time): UTC+8',
  },
  {
    value: 'UTC+5:30',
    label: 'IST (Indian Standard Time): UTC+5:30',
  },
  {
    value: 'UTC+8',
    label: 'SGT (Singapore Time): UTC+8',
  },
  {
    value: 'UTC-3',
    label: 'ART (Argentina Time): UTC-3',
  },
  {
    value: 'UTC-3',
    label: 'BRT (Brasília Time): UTC-3',
  },
  {
    value: 'UTC+11',
    label:
      'EST (Eastern Standard Time) - Australia: UTC+11 (During daylight saving time)',
  },
  {
    value: 'UTC-3:30',
    label: 'NST (Newfoundland Standard Time): UTC-3:30',
  },
  {
    value: 'UTC-10',
    label: 'HST (Hawaii Standard Time): UTC-10',
  },
  {
    value: 'UTC-9',
    label: 'AKST (Alaska Standard Time): UTC-9',
  },
  {
    value: 'UTC-9',
    label: 'NST (Nome Standard Time): UTC-9',
  },
  {
    value: 'UTC+1',
    label: 'WAT (West Africa Time): UTC+1',
  },
  {
    value: 'UTC+3',
    label: 'EAT (East Africa Time): UTC+3',
  },
];

const GENDERS = [
  { value: 'male', label: 'Male' },
  {
    value: 'female',
    label: 'Female',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export function BasicInformation(props) {
  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);

  const basicUserInfo = useAPI('/api/user/info');

  return (
    <>
      {/* <div className='container m-auto text-center'>
        <ContentSection
          title='Intake Questionsaire'
          description='Please provide the below information as accurately as you can.'
        />
      </div> */}

      <Animate type='pop'>
        <Card
          restrictWidth
          className='mt-3 m-auto'
          loading={basicUserInfo.loading}
        >
          <Form
            inputs={{
              firstName: {
                label: 'First Name',
                type: 'text',
                placeholder: 'First Name',
                required: true,
                value: basicUserInfo.data?.firstName,
              },
              lastName: {
                label: 'Last Name',
                placeholder: 'Last Name',
                type: 'text',
                required: true,
                value: basicUserInfo.data?.lastName,
              },
              dob: {
                label: 'Date Of Birth',
                type: 'date',
                required: true,
                value: basicUserInfo.data?.dob,
              },
              tz: {
                label: 'Time Zone',
                type: 'select',
                options: COMMON_TZ,
                placeholder: 'Select your timezone',
                required: true,
              },
              gender: {
                label: 'Gender',
                type: 'select',
                options: GENDERS,
                required: true,
              },
              phone: {
                label: 'Phone Number',
                type: 'phone',
                placeholder: '202-555-1212',
                required: false,
                value: basicUserInfo.data?.phone,
              },
              pocFirstName: {
                label: 'POC First Name',
                type: 'text',
                placeholder: "Emergency Contact's First Name",
                required: false,
                value: basicUserInfo.data?.pocFirstName,
              },
              pocLastName: {
                label: 'POC Last Name',
                placeholder: "Emergency Contact's Last Name",
                type: 'text',
                required: false,
                value: basicUserInfo.data?.pocLastName,
              },
              pocPhone: {
                label: 'POC Phone Number',
                placeholder: "Emergency Contact's Phone Number",
                type: 'phone',
                required: false,
                value: basicUserInfo.data?.pocPhone,
              },
              pocRelationship: {
                label: 'Relation to Client',
                placeholder: "Emergency Contact's Relationship to Client",
                type: 'text',
                required: false,
                value: basicUserInfo.data?.pocRelationship,
              },
            }}
            method='POST'
            buttonText='SAVE'
            url={`/api/user/info`}
            callback={() => props.next()}
          />
        </Card>
      </Animate>
    </>
  );
}
