import React, { useContext, useState } from 'react';
import Logo from 'components/userLogo/logo';
import ContentSection from 'components/pageTopSection';
import { AuthContext } from 'components/lib';

export function Welcome(props) {
  const context = useContext(AuthContext);

  return (
    <>
      <div className='container m-auto text-center'>
        <div className='lg:relative'>
          {/* <Logo /> */}
          <ContentSection
            title=''
            subtitle={'We are happy to have you here ' + context.user.name}
            description='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam.'
          />
        </div>
      </div>
      <div className='container m-auto mt-8 text-center'>
        <button
          type='button'
          className='curson-default w-full m-auto text-gray-900 border-2 border-gray-800 font-medium rounded-lg text-lg px-5 py-6 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-40'
        >
          Basic Information
        </button>
        <button
          type='button'
          className='cursor-default w-full m-auto text-gray-900 border-2 border-gray-800 font-medium rounded-lg text-lg px-5 py-6 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-40'
        >
          Consent And Acknowledgement Form
        </button>
        <button
          type='button'
          className='cursor-default w-full m-auto text-gray-900 border-2 border-gray-800 font-medium rounded-lg text-lg px-5 py-6 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-40'
        >
          Intake Questionaire
        </button>

        <button
          type='button'
          className='cursor-default w-full m-auto text-gray-900 border-2 border-gray-800 font-medium rounded-lg text-lg px-5 py-6 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-40'
        >
          Specific Areas Of Interest
        </button>
      </div>
    </>
  );
}
