import React from 'react';

// FormInput component
function FormBaseInput({ label, name, type, placeholder, value, onChange }) {
  return (
    <div className="sm:col-span-3">
    <div className="mt-2">
      <label htmlFor={name} className="block text-gray-700">
        {label}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="w-full px-3 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1"
      />
    </div>
    </div>
  );
}

export default FormBaseInput;