import { Dashboard } from 'views/dashboard/dashboard';
import { Help } from 'views/dashboard/help';
import { OnboardingView } from 'views/onboarding/onboarding';
import { Chat } from 'views/chat/chat';
import { OnboardingViewV2 } from 'views/onboarding/onboardingV2';

const Routes = [
  {
    path: '/chat',
    view: Chat,
    layout: 'chat',
    permission: 'user',
    title: 'Chat',
  },
  // {
  //   path: '/dashboard',
  //   view: Dashboard,
  //   layout: 'app',
  //   permission: 'user',
  //   title: 'Dashboard',
  // },
  // {
  //   path: '/welcome',
  //   view: OnboardingView,
  //   layout: 'onboarding',
  //   permission: 'user',
  //   title: 'Welcome',
  // },
  {
    path: '/welcome',
    view: OnboardingViewV2,
    layout: 'onboardingV2',
    permission: 'user',
    title: 'Welcome',
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help',
  },
];

export default Routes;
