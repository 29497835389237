import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Loader } from 'feather-icons-react/build/IconComponents';
import { Button } from 'components/lib';

export function Interest(props) {
  const [interests, setInterests] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleInterestChange = id => {
    setInterests(prevInterests =>
      prevInterests.map(interest =>
        interest.id === id
          ? { ...interest, isChecked: !interest.isChecked }
          : interest
      )
    );
  };

  const getAvailableInterest = async () => {
    try {
      setLoading(true);
      const traits = await axios.get('/api/traits');

      setInterests(
        traits.data.data.map(trait => ({
          ...trait,
          label: trait.name,
          isChecked: false,
        }))
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAvailableInterest();
  }, []);

  if (loading) return <Loader />;

  async function saveInterests(selectedInterest) {
    try {
      setLoading(true);
      await axios({
        method: 'post',
        url: '/api/user/trait',
        data: { traits: selectedInterest },
      });
    } catch (error) {
      setLoading(false);
    }
  }

  async function onSaveInterest() {
    const selectedInterests = interests
      .filter(interest => interest.isChecked)
      .map(interest => interest._id);

    if (selectedInterests?.length > 0) {
      await saveInterests(selectedInterests);
      props.finish();
    }
  }

  return (
    <div className='container m-auto mt-8 w-full'>
      <ul className='flex flex-wrap'>
        {interests?.map(interest => (
          <li key={interest.id} className='w-1/3 p-2'>
            <label>
              <input
                type='checkbox'
                className='mr-2'
                checked={interest.isChecked}
                onChange={() => handleInterestChange(interest.id)}
              />
              {interest.label}
            </label>
          </li>
        ))}
      </ul>
      <Button
        text='Save Interest'
        color='green'
        className='mx-auto mt-6 w-1/2'
        iconButton
        action={onSaveInterest}
      />
    </div>
  );
}
