import React from 'react';

const ContentSection = ({ title, subtitle, headingthree, description }) => {
  return (
    <>
      <h1 className='w-full text-2xl font-normal leading-normal text-gray-900 dark:text-white mt-3 lg:absolute lg:top-0'>
        {title}
      </h1>
      <h2 className='text-2xl font-normal leading-normal text-gray-900 dark:text-white'>
        {subtitle}
      </h2>
      <h3 className='text-2xl font-normal leading-normal text-gray-900 dark:text-white'>
        {headingthree}
      </h3>
      <p className=' w-full m-auto my-3 text-gray-900'>{description}</p>
    </>
  );
};

export default ContentSection;
