import { Acknowledge } from 'components/acknowledgementForm';

export function Acknowledgement(props) {

  return (
    <>
    < Acknowledge />

    </>
  );
}