import React, { useState } from 'react';
import SideBar from 'components/chatSideBar/chatSideBar';
import ChatView from 'components/chatView/chatView';
import useChats from 'components/hooks/useChats';
import useMessageCollection from 'components/hooks/useMessageCollection';
import { ModalV2 } from 'components/lib';
import { Personalities } from 'views/personalities/personalities';
import Axios from 'axios';
import { ProfileModalBase } from 'views/account/profileModalBase';

export function Chat(props) {
  const [showPersonalityModal, setShowPersonalityModal] = useState(true);
  const [showProfile, setShowProfile] = useState(false);
  const [isSidebarOpen,setIsSidebarOpen]=useState(true)
  const { chatHistory, setSelectedChat, getChatHistory } = useChats();
  const {
    ask,
    messages,
    addMessage,
    sessionId,
    setSessionId,
    getMessagesBySessionId,
    setMessages,
    selectedPersonality,
    setSelectedPersonality,
    initalChatLoading,
  } = useMessageCollection();

  async function getConvoByBotId(botId) {
    try {
      const response = await Axios.get(`/api/conversation/bot/${botId}`);
      return response.data;
    } catch (error) {}
  }

  async function selectChat(chat) {
    setSelectedPersonality(chat.bot_id);
    setSelectedChat(chat);
    setSessionId(chat.session_id);
    await getMessagesBySessionId(chat.session_id);
  }

  async function resetChat() {
    setSelectedPersonality(null);
    setSelectedChat(null);
    setSessionId(null);
    setMessages([]);
    await getChatHistory();
  }

  async function createNewChat(personality) {
    const chat = await getConvoByBotId(personality._id);
    if (chat) {
      await selectChat(chat);
    } else {
      setSelectedChat(null);
      setMessages([]);
      setSelectedPersonality(personality);

      //Using below string to ignore this message in the history
      await ask('Xq7#zG!pL3%vMj1^&W8kY', personality?._id, true);
    }

    togglePersonalityModal();
    await getChatHistory();
  }

  async function onAsk(prompt) {
    await ask(prompt);
    if (!sessionId) {
      await getChatHistory();
    }
  }

  const togglePersonalityModal = startChat =>
    setShowPersonalityModal(!showPersonalityModal);

  const toggleProfileModal = () => setShowProfile(!showProfile);

  // const [modalOpen, setModalOpen] = useState(false);

  // useEffect(() => {
  //   const apiKey = window.localStorage.getItem('api-key');
  //   if (!apiKey) {
  //     setModalOpen(true);
  //   }
  // }, []);

  return (
    <div className='flex transition duration-500 ease-in-out'>
      {showProfile && (
        <ModalV2 isOpen={showProfile} onClose={toggleProfileModal}>
          <ProfileModalBase />
        </ModalV2>
      )}
      {showPersonalityModal && (
        <ModalV2 isOpen={showPersonalityModal} onClose={togglePersonalityModal}>
          <Personalities
            initalChatLoading={initalChatLoading}
            onNewChat={createNewChat}
          />
        </ModalV2>
      )}

      <SideBar
        openProfileModal={toggleProfileModal}
        resetChat={resetChat}
        onSelectChat={selectChat}
        chatHistory={chatHistory}
        setIsSidebarOpen={setIsSidebarOpen}
        onCreateNewChat={togglePersonalityModal}
      />
      <ChatView
        selectedPersonality={selectedPersonality}
        ask={onAsk}
        open={isSidebarOpen}
        messages={messages}
        addMessage={addMessage}
      />
    </div>
  );
}
