import React from 'react';
import { Card, Checkbox, useAPI } from 'components/lib';

export function Consent({ userAgreed, toggleUserConsent }) {
  const textContent = useAPI('/api/textContent/consentText');

  return (
    <Card loading={textContent.loading} noPadding>
      <div className='flex flex-col h-full'>
        <p className='flex-1 w-full m-auto my-3 text-gray-900'>
          {textContent.data?.consentText}
        </p>
        <div className='mt-auto'>
          <Checkbox
            option='I have read and agree to the terms and conditions of the consent and acknowledgement form.'
            value={userAgreed}
            checked={userAgreed}
            callback={(index, checked, option) => toggleUserConsent()}
          />
        </div>
      </div>
    </Card>
  );
}
