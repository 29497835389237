import { useState, useRef, useEffect } from 'react';
import { MdSend } from 'react-icons/md';
import { replaceProfanities } from 'no-profanity';
import Message from 'components/chatMessage/chatMessage';
import Thinking from 'components/chatThinking/chatThinking';
import { MdPerson } from 'react-icons/md';

const ChatView = ({
  ask,
  addMessage,
  messages,
  personality,
  selectedPersonality,
  open
}) => {
  const messagesEndRef = useRef();
  const inputRef = useRef();
  const [formValue, setFormValue] = useState('');
  const [thinking, setThinking] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const updateMessage = (newValue, ai = false) => {
    const id = Date.now() + Math.floor(Math.random() * 1000000);
    const newMsg = {
      id: id,
      createdAt: Date.now(),
      text: newValue,
      role: ai ? 'assistant' : 'user',
      ai: ai,
      selected: 'chatgpt-3.5-turbo',
    };

    addMessage(newMsg);
  };

  const sendMessage = async e => {
    e.preventDefault();

    const cleanPrompt = replaceProfanities(formValue);

    const newMsg = cleanPrompt;

    setThinking(true);
    setFormValue('');
    updateMessage(newMsg, false);

    try {
      await ask(newMsg);
    } catch (err) {
      window.alert(`Error: ${err} please try again later`);
    }

    setThinking(false);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      sendMessage(e);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, thinking]);

  useEffect(() => {
    if (personality && messages.length) {
      inputRef.current.focus();
    }
  }, [personality, messages.length]);

  console.log('selectedPersonality', selectedPersonality);

  return (
    <>
    
      {selectedPersonality ? (
        <div className={`flex  w-72 pt-5 pl-5 ${open?"ml-48":"ml-14"} `}>
          <MdPerson className='w-6 m-0' />
          <span className='text-black'>
            Dr {selectedPersonality?.name.split(' ')[0]}
           
          </span>
        </div>
      ) : null}
       {/* {open?<div>open</div>:<div>close</div>} */}
      <main className='relative flex flex-col h-screen overflow-hidden bg-white p-0 sm:mr-[17%] sm:ml-[6%] mt-12'>
        <section className='flex flex-col flex-grow w-full px-4 overflow-y-scroll sm:px-0 md:px-0'>
          {messages.length ? (
            messages.map((message, index) => (
              <Message
                selectedPersonality={selectedPersonality}
                key={index}
                message={{ ...message }}
              />
            ))
          ) : (
            <div className='flex my-2'>
              <div className='w-screen overflow-hidden'></div>
            </div>
          )}
          {thinking && <Thinking />}

          <span ref={messagesEndRef}></span>
        </section>
        {personality || messages.length ? (
          <form
            className='flex flex-col px-10 my-5 md:px-20 join sm:flex-row'
            onSubmit={sendMessage}
          >
            <div className='flex items-stretch justify-between w-full relative'>
              {/* <MdSend size={30}  className='absolute left-2 top-2'/> */}
              <input
                placeholder=''
                ref={inputRef}
                className='w-full grow input input-bordered join-item max-h-[20rem] min-h-[3rem] px-10 border'
                value={formValue}
                onKeyDown={handleKeyDown}
                onChange={e => setFormValue(e.target.value)}
              />
              <button
                type='submit'
                className='join-item btn  '
                disabled={!formValue}
              >
                <MdSend
                  size={30}
                  className='absolute top-[10px] right-[10px]'
                />
              </button>
            </div>
          </form>
        ) : null}
      </main>
    </>
  );
};

export default ChatView;
