import React from 'react';
import logo from '../../images/per-1.png';

function Logo() {
  return (
    <div className='img'>
       <img className="w-16 h-16 border-2 rounded-full border-black" src={logo} alt="User Logo" />
    </div>
  );
}

export default Logo;