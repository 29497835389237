import axios from 'axios';
import { useState, useEffect } from 'react';
const Settings = require('../../settings.json');

/**
 * A custom hook for managing the conversation between the user and the AI.
 *
 * @returns {Object} An object containing the `messages` array, the `addMessage` function, the `clearMessages` function, and the `loadMessage` function.
 */
const useChats = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [chatLoading, setChatLoading] = useState(true);
  const [selectedChat, setSelectedChat] = useState(null);

  const getChatHistory = async () => {
    try {
      setChatLoading(true);
      const res = await axios.get(
        `${Settings[process.env.NODE_ENV].server_url}/api/conversation`
      );
      setChatHistory(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      setChatLoading(false);
    }
  };

  useEffect(() => {
    getChatHistory();
  }, []);

  const deleteConversation = async sessionId => {
    try {
      const res = await axios.delete(
        `${Settings[process.env.NODE_ENV].server_url}/api/conversation`,
        {
          sessionId,
        }
      );
      getChatHistory();
    } catch (err) {
      console.log(err);
    }
  };

  return {
    chatHistory,
    chatLoading,
    setSelectedChat,
    deleteConversation,
    getChatHistory,
    selectedChat,
  };
};

export default useChats;
